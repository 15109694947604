import React from 'react'
import { Helmet } from 'react-helmet'
import Layout from '../components/Layout/index'
import './styles/imprensa.scss'

// assets
import email from '../images/imprensa/email.svg'
import phone from '../images/imprensa/phone.svg'
// import site from '../images/imprensa/site.svg'

export default function Imprensa () {
  const breadC = {
    visible: true,
    color: 'black',
    items: [
      { path: '/', name: 'Home' },
      { path: '/imprensa/', name: 'Imprensa' }
    ]
  }
  return (
    <Layout breadCrumb={breadC}>
      <Helmet>
        <html lang="pt-BR" amp />
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1"></meta>
        <title>Imprensa - Dia Supermercados</title>
        <meta name="description" content="Contatos para imprensa do Dia Supermercados. Acesse nossa página e saiba mais." />
        <meta name="keywords" content="imprensa" />
        <meta name="author" content="DIA Supermercados" />
      </Helmet>
      <section className="bg-imprensa py-5">
        <div className="py-5">
          <div className="container  py-5">
            <div className="card card-imprensa px-3 px-lg-5 py-4 mx-0 mx-lg-4">
              <h1 className="mb-5 text-red font-size-30px">Contatos para Imprensa</h1>
              <h3 className="mb-3 text-uppercase">Imagem Corporativa</h3>
              <div className="ml-0 row">
                <div className="col-lg-4 border-bottom mb-4 px-0 pb-lg-0 pb-3">
                  <div className="d-flex mb-3 align-items-center">
                    <img className="pr-3" src={phone} alt='icone telefone'/>
                    <span className="font-size-1125">+55 (11) 3526-4500</span>
                  </div>
                </div>
                <div className="col-lg-4 pl-lg-4 mb-3 px-0">
                  <div className="d-flex align-items-center">
                    <img className="pr-3" src={email} alt='icone email'/>
                    <a className="font-size-09" href="mailto:imprensadia@iccom.com.br">imprensadia@iccom.com.br</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}
